import TemplatesModal from './TemplatesModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrderPolicy } from '../../../../core/logic/policyLogic';

function mapStateToProps(state: AppState) {
  return {
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
  };
}

export default connect(mapStateToProps)(TemplatesModal);
