import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import styles from './StickyV2.scss';
import dataHooks from '../../data-hooks';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import stylesParams from '../../stylesParams';

function getTopElement(offsetX: number): HTMLElement | null {
  try {
    const topElements = document.elementsFromPoint(offsetX, 0);
    return topElements.find((element) => !element.className.includes('modal-overlay')) as HTMLElement;
  } catch (e) {
    return null;
  }
}

export function isElementInPosition(desiredPosition: 'fixed' | 'sticky', element?: Element | null): boolean {
  if (!element) {
    return false;
  }

  try {
    const { position } = window.getComputedStyle(element);
    return position === desiredPosition;
  } catch (e) {
    return false;
  }
}

function isDomElementVisible(element: HTMLElement | null): boolean {
  if (!element) {
    return false;
  }

  try {
    const { display, visibility, opacity } = window.getComputedStyle(element);
    return display !== 'none' && visibility !== 'hidden' && Number(opacity) === 1;
  } catch (e) {}
  return false;
}

function calculateElementOffset(element: HTMLElement | null, isEditorX: boolean) {
  if (
    element &&
    isDomElementVisible(element) &&
    !isElementTranslatedVertically(element) &&
    (isElementInPosition('fixed', element) || isElementInPosition('fixed', element?.parentElement))
  ) {
    return element.offsetHeight + element.offsetTop;
  } else {
    if (isElementInPosition('sticky', element) && isEditorX) {
      return element?.offsetHeight;
    } else {
      return 0;
    }
  }
}

function isElementTranslatedVertically(element: HTMLElement | null) {
  if (!element) {
    return false;
  }

  try {
    const { transform } = window.getComputedStyle(element);
    const match = transform.match(
      /matrix\((-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*)\)/,
    );
    return (
      transform !== 'none' &&
      (!match || (Number(match[match.length - 1]) > -100 && Number(match[match.length - 1]) < -5))
    );
  } catch (e) {
    return 0;
  }
}

function getFreemiumBarHeight() {
  try {
    return document.getElementById('WIX_ADS')?.offsetHeight || 0;
  } catch (e) {
    return 0;
  }
}

export function getHeaderElement(isEditorX: boolean): HTMLHeadElement {
  if (isEditorX) {
    return (
      (document.querySelector('#SITE_HEADER') as HTMLHeadElement) ||
      (document.querySelector('header') as HTMLHeadElement)
    );
  } else {
    return document.querySelector('#SITE_HEADER') as HTMLHeadElement;
  }
}

export function calculateTopValue(offsetX: number, isEditorX: boolean): number {
  try {
    const topHeader = getHeaderElement(isEditorX);
    const topElement = topHeader?.textContent ? topHeader : getTopElement(offsetX);
    const topElementOffset = calculateElementOffset(topElement, isEditorX);
    let result = topElementOffset || getFreemiumBarHeight();

    if (isEditorX && topElementOffset !== 0) {
      result += getFreemiumBarHeight();
    }

    return result;
  } catch (e) {}

  return 0;
}

export interface StickyProps {
  className?: string;
  offset?: number;
  onChange?: (isSticky: boolean) => void;
  isEditorX?: boolean;
}

const Sticky: React.FC<StickyProps> = ({ className, offset = 0, children, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(0);
  const [isAtTopOfViewPort, setAtTopOfViewPort] = useState(false);
  const tpaStyles = useStyles();
  const isEditorX = tpaStyles.get(stylesParams.responsive);

  useEffect(() => {
    setImmediate(() => {
      setTop(calculateTopValue(ref.current?.offsetLeft || 0, isEditorX));
    });

    const handleObservedMutation = () => {
      setTimeout(() => {
        setTop(calculateTopValue(ref.current?.offsetLeft || 0, isEditorX));

        const rectTop = ref.current?.getBoundingClientRect().top;

        if (onChange && rectTop !== undefined) {
          const newAtTopOfViewPort = rectTop - top <= 0;

          if (newAtTopOfViewPort !== isAtTopOfViewPort) {
            setAtTopOfViewPort(newAtTopOfViewPort);
            onChange(newAtTopOfViewPort);
          }
        }
      }, 50);
    };

    if (typeof window !== 'undefined') {
      try {
        const throttled = _.throttle(handleObservedMutation, 50);
        const observer = new MutationObserver(throttled);
        const header = getHeaderElement(isEditorX);
        observer.observe(header, { attributes: true });
        header.addEventListener('transitionend', throttled);

        return () => {
          observer.disconnect();
          header.removeEventListener('transitionend', throttled);
        };
      } catch (e) {}
    }
  }, [ref, onChange, top, isAtTopOfViewPort]);

  return (
    <div
      ref={ref}
      className={`${className} ${styles.wrapper}`}
      style={{ top: top + offset }}
      data-hook={dataHooks.stickyNavigationHeader}
    >
      {children}
    </div>
  );
};

Sticky.displayName = 'Sticky';

export default Sticky;
