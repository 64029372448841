import CheckoutPayments from './CheckoutPayments';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setCashierPayment,
  setPayment,
  setPendingCashierPayment,
  setTip,
  setCashierPaymentDone,
} from '../../../../state/checkout/checkout.actions';
import { findTipCharge, getTipPresets } from '../../../../state/selectors/orderSelector';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { partnerTipPresets } from '../TipPicker/CustomTipPickerUtils';

function mapStateToProps(state: AppState, ownProps: any) {
  const { priceComponents } = cartSummarySelector(state);
  const isByDeliveryPartner = Boolean(state.checkout.deliveryProvider);

  return {
    ...ownProps,
    payments: state.checkout.payments,
    shouldDisplayTip:
      !!findTipCharge(state.session.menu.chargesV2) && state.checkout.pendingCashierPayment.paymentMethod !== 'offline',
    tip: state.checkout.tip,
    isLoading: state.checkout.isLoading,
    currency: state.session.restaurant.currency,
    locale: state.session.restaurant.locale,
    totalOrderPrice: priceComponents.total,
    tipCharge: priceComponents.tip,
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    tipPresets: isByDeliveryPartner ? partnerTipPresets : getTipPresets(state.session.restaurant),
    isByDeliveryPartner,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setTip,
      setPayment,
      setCashierPayment,
      setPendingCashierPayment,
      setCashierPaymentDone,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPayments);
