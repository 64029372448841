import { createSelector } from 'reselect';
import uuid from 'uuid/v4';
import { AppState } from '../createStore';
import { PartialLocation } from '../../core/oloApi';
import { BusinessNotifications, getAllBusinessNotifications, Restaurant } from '@wix/restaurants-client-logic';
import _ from 'lodash';

export const getOpenLocations = (
  locations: PartialLocation[],
  products: Restaurant['products'],
  hasAnyPaymentMethods: boolean,
  restaurant: Restaurant,
) => {
  return (_.isEmpty(locations) ? [restaurant as PartialLocation] : locations)
    .map((location) => {
      const notifications = getAllBusinessNotifications(
        {
          ...location,
          products,
        } as Restaurant,
        hasAnyPaymentMethods,
      );
      return {
        location,
        notification: notifications.length > 0 ? notifications[0].notification : undefined,
      };
    })
    .filter(({ notification }) => {
      return (
        notification !== BusinessNotifications.RestaurantClosed && notification !== BusinessNotifications.OlOunavailable
      );
    })
    .map(({ location }) => location);
};

/**
 * @returns only open locations, that is OLO is available and the location is not closed
 */
export const selectOpenLocations = createSelector(
  [
    (state: AppState) => state.session.locations,
    (state: AppState) => state.session.restaurant.products,
    (state: AppState) => state.session.paymentMethods.length > 0,
    (state: AppState) => state.session.restaurant,
  ],
  getOpenLocations,
);

/**
 * @returns only open locations, that is OLO is available and the location is not closed
 */
export const selectLocationsWithNotification = createSelector(
  [
    (state: AppState) => state.session.locations,
    (state: AppState) => state.session.restaurant.products,
    (state: AppState) => state.session.paymentMethods.length > 0,
  ],
  (locations: PartialLocation[], products: Restaurant['products'], hasAnyPaymentMethods: boolean) => {
    return locations.map((location) => {
      const notifications = getAllBusinessNotifications(
        {
          ...location,
          products,
        } as Restaurant,
        hasAnyPaymentMethods,
      );
      return {
        location,
        notification: notifications.length > 0 ? notifications[0].notification : undefined,
      };
    });
  },
);
export type LocationsWithNotification = ReturnType<typeof selectLocationsWithNotification>;

/**
 * @returns all locations. A non-ML restaurant, will be treated as single location
 */
export const selectLocations = createSelector(
  [(state: AppState) => state.session.restaurant, (state: AppState) => state.session.locations],
  (restaurant: Restaurant, locations: PartialLocation[]) => {
    return _.isEmpty(locations) ? [restaurant] : (locations as Restaurant[]);
  },
);
